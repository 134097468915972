<template>
  <div>
    <div
      v-if="!searched"
      class="box">
      <div class="level">
        <span class="is-size-4">Search Users:</span>
      </div>
      <div class="level">
        <div class="level-right">
          <div class="level-item">
            <div class="field">
              <input v-model.trim="id" @keyup.enter="search" class="input" type="text" placeholder="ID...">
            </div>
          </div>
          <div class="level-item">
            <div class="field">
              <input v-model="firstName" @keyup.enter="search" class="input" type="text" placeholder="First Name...">
            </div>
          </div>
          <div class="level-item">
            <div class="field">
              <input v-model="lastName" @keyup.enter="search" class="input" type="text" placeholder="Last Name...">
            </div>
          </div>
          <div class="level-item">
            <div class="field">
              <input v-model.trim="email" @keyup.enter="search" class="input" type="text" placeholder="Email...">
            </div>
          </div>
        </div>
      </div>
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <div class="field">
              <b-select
                placeholder="Select a Club..."
                v-model="club">
                <option
                  v-for="club in filterClubs"
                  :value="club.id"
                  :key="club.id">
                  {{ club.name }}
                </option>
              </b-select>
            </div>
          </div>
          <div class="level-item">
            <b-switch
              v-model="includeTestClubs"
              @input="club=null">
              Include Test Clubs
            </b-switch>
          </div>
        </div>
        <div class="level-right">
          <b-button
            class="is-primary"
            :disabled="isDisabled"
            @click="search">Search</b-button>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="box">
        <div class="field">
          <div class="control">
            <input v-model="searchQuery" class="input" type="text" placeholder="Search...">
          </div>
        </div>
        <div>
          <b-button
            class="is-primary"
            @click="newSearch">
            New Search
          </b-button>
        </div>
      </div>
      <div class="box">
        <app-spinner v-if="loading"></app-spinner>
        <b-table v-else
          :data="filteredUsers"
          :per-page="perPage"
          narrowed
          hoverable
          mobile-cards
          paginated
          pagination-simple
          @select="selected"
          :row-class="(row, index) => (row.deleted_at != null) ? 'deleted-user' : 'clickable'">
          <template slot-scope="props">
            <b-table-column field="id" label="User ID" sortable>
              {{ props.row.id }}
            </b-table-column>
            <b-table-column field="email" label="Email" sortable>
              {{ props.row.email }}
            </b-table-column>
            <b-table-column field="first_name" label="First" sortable>
              {{ props.row.first_name }}
            </b-table-column>
            <b-table-column field="last_name" label="Last" sortable>
              {{ props.row.last_name }}
            </b-table-column>
            <b-table-column field="registered_at" label="Registered At" sortable>
              <span v-if="props.row.registered_at">
                {{ props.row.registered_at | formatDate('M/D/YY h:mmA') }}
              </span>
              <span v-else>-</span>
            </b-table-column>
          </template>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>No results</p>
              </div>
            </section>
          </template>
          <template slot="bottom-left">
            <b-select v-model="perPage">
              <option value="10">10 per page</option>
              <option value="15">15 per page</option>
              <option value="20">20 per page</option>
              <option value="50">50 per page</option>
              <option value="100">100 per page</option>
            </b-select>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { remove, sortBy } from 'lodash';
import api from '@/http-playmetrics';
import Spinner from '@/components/common/Spinner';

export default {
  components: {
    'app-spinner': Spinner,
  },

  data() {
    return {
      loading: true,
      perPage: 20,
      searchQuery: '',
      clubs: [],
      firstName: '',
      lastName: '',
      email: '',
      id: '',
      user_id: 0,
      includeTestClubs: false,
      club: null,
      matchingUsers: [],
      // exatcMatchUsers: [],
      searched: false,
    };
  },

  computed: {
    filteredUsers() {
      return this.matchingUsers.filter(user =>
        user.email.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
        user.first_name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
        user.last_name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
        user.id.toString().toLowerCase().includes(this.searchQuery.toLowerCase()),
      );
    },

    filterClubs() {
      if (this.includeTestClubs) {
        return this.clubs;
      } else {
        return this.clubs.filter(user =>
          user.is_test_club === this.includeTestClubs);
      }
    },

    isDisabled() {
      if (this.firstName !== '' || this.lastName !== '' || this.email !== '' || this.id !== '' || this.club !== null) {
        return false;
      }
      return true;
    },
  },

  methods: {
    selected(user) {
      this.$router.push(`/users/${user.id}`);
    },

    fetchClubs() {
      api().get('admin/clubs')
        .then((response) => {
          this.clubs = sortBy(response.data, 'name');
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    search() {
      let exactMatches = [];
      this.user_id = parseInt(this.id, 10);
      let postData = { email: this.email, first_name: this.firstName, last_name: this.lastName, club_id: this.club };
      if (!Number.isNaN(this.user_id)) {
        postData = { id: this.user_id };
      }
      api().post('admin/users/search', postData).then((response) => {
        this.matchingUsers = response.data.results;
        if (this.firstName !== '') {
          exactMatches = remove(this.matchingUsers, user =>
            user.first_name.toLowerCase() === this.firstName.toLowerCase());
          this.matchingUsers = exactMatches.concat(this.matchingUsers);
        }
        if (this.lastName !== '') {
          exactMatches = remove(this.matchingUsers, user =>
            user.last_name.toLowerCase() === this.lastName.toLowerCase());
          this.matchingUsers = exactMatches.concat(this.matchingUsers);
        }
        this.searched = true;
      });
    },

    newSearch() {
      this.searched = false;
      this.matchingUsers = [];
      // this.exatcMatchUsers = [];
      this.firstName = '';
      this.lastName = '';
      this.id = '';
      this.email = '';
      this.club = null;
      this.includeTestClubs = false;
    },
  },

  created() {
    this.fetchClubs();
  },
};
</script>

<style>
  .deleted-user {
    text-decoration: line-through;
    background-color: lightgrey;
  }
</style>
