<template>
  <div>
    <div
      v-if="!searched"
      class="box">
      <div class="level">
        <span class="is-size-4">Search Players:</span>
      </div>
      <nav class="level">
        <div class="level-left">
          <div class="level-item">
            <div class="field">
              <div class="control">
                <input v-model="name" @keyup.enter="search" class="input" type="text" placeholder="Name...">
              </div>
            </div>
          </div>
          <div class="level-item">
            <div class="block">
              <b-checkbox v-model="genders"
                native-value="M">
                Male
              </b-checkbox>
              <b-checkbox v-model="genders"
                native-value="F">
                Female
              </b-checkbox>
            </div>
          </div>
        </div>
      </nav>
      <nav class="level">
        <div class="level-left">
          <div class="level-item">
            <b-select v-model="club" placeholder="Select a club...">
              <option
                v-for="club in filteredClubs"
                :value="club.id"
                :key="club.id">
                {{ club.name }}
              </option>
            </b-select>
          </div>
          <div class="level-item">
            <b-switch
              v-model="includeTestClubs"
              @input="club=null">
              Include Test Clubs
            </b-switch>
          </div>
        </div>
        <div class="level-right">
          <b-button
            class="is-primary"
            :disabled="isDisabled"
            @click="search">
            Search
          </b-button>
        </div>
      </nav>
    </div>
    <div v-else>
      <div class="box">
        <div>{{ searchCriteria }}<br><br></div>
        <b-button
          class="is-primary"
          @click="newSearch">
          New Search
        </b-button>
      </div>
      <div class="box">
        <div class="field">
          <div class="control">
            <input v-model="searchQuery" class="input" type="text" placeholder="Filter...">
          </div>
        </div>
        <app-spinner v-if="loading"></app-spinner>
        <b-table v-else
          :data="filteredPlayers"
          :per-page="perPage"
          narrowed
          hoverable
          mobile-cards
          detailed
          detail-key="id"
          paginated
          pagination-simple
          @select="selected"
          :row-class="(function() {return 'clickable';})">
          <template slot-scope="props">
            <b-table-column field="id" label="Club ID" sortable>
              {{ props.row.club_id }}
            </b-table-column>
            <b-table-column field="id" label="Player ID" sortable>
              {{ props.row.id }}
            </b-table-column>
            <b-table-column field="first_name" label="First Name" sortable>
              {{ props.row.first_name }}
            </b-table-column>
            <b-table-column field="last_name" label="Last Name" sortable>
              {{ props.row.last_name }}
            </b-table-column>
            <b-table-column field="gender" label="Gender" sortable>
              {{ props.row.gender }}
            </b-table-column>
            <b-table-column field="birth_year" label="Birth Year" sortable>
              {{ props.row.birth_year }}
            </b-table-column>
          </template>
          <template slot="detail" slot-scope="props">
            <b>Player ID</b>: {{ props.row.id }}<br/>
            <b>Club ID</b>: {{ props.row.club_id }}<br/>
            <b>Teams</b>: {{ props.row.team_name }}<br/>
            <b>Gender</b>: {{ props.row.gender }}<br/>
            <b>First Name</b>: {{ props.row.first_name }}<br/>
            <b>Last Name</b>: {{ props.row.last_name }}<br/>
            <b>Birth Date</b>: {{ props.row.birth_date | formatDate('llll') }}<br/>
            <b>Birth Year</b>: {{ props.row.birth_year }}<br/>
            <b>Created At:</b> {{ props.row.created_at }}<br/>
            <b>Updated At:</b> {{ props.row.updated_at }}<br/>
          </template>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>No results</p>
              </div>
            </section>
          </template>
          <template slot="bottom-left">
            <b-select v-model="perPage">
              <option value="10">10 per page</option>
              <option value="15">15 per page</option>
              <option value="20">20 per page</option>
              <option value="50">50 per page</option>
              <option value="100">100 per page</option>
            </b-select>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { sortBy } from 'lodash';
import api from '@/http-playmetrics';
import Spinner from '@/components/common/Spinner';

export default {
  components: {
    'app-spinner': Spinner,
  },

  data() {
    return {
      loading: true,
      players: [],
      clubs: [],
      perPage: 20,
      name: '',
      gernder: '',
      club: null,
      includeTestClubs: false,
      searched: false,
    };
  },

  computed: {
    searchQuery: {
      get() {
        return this.$store.getters.playersFilters.searchQuery;
      },
      set(searchQuery) {
        return this.$store.dispatch('setPlayersFiltersSearchQuery', searchQuery);
      },
    },
    genders: {
      get() {
        return this.$store.getters.playersFilters.genders;
      },
      set(genders) {
        return this.$store.dispatch('setPlayersFiltersGenders', genders);
      },
    },

    searchCriteria() {
      let query = this.searchQuery;
      if (this.name.length > 0) {
        query += this.name;
      }
      if (this.genders.length > 0) {
        query += ` Gender: ${this.genders}`;
      }
      if (this.club) {
        query += ` Club: ${this.club}`;
      }
      return query;
    },

    filteredPlayers() {
      return this.players.filter(player =>
        (
          player.first_name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          player.last_name.toLowerCase().includes(this.searchQuery.toLowerCase())
        ),
      );
    },
    filteredClubs() {
      if (this.includeTestClubs) {
        return this.clubs;
      } else {
        return this.clubs.filter(user =>
          user.is_test_club === this.includeTestClubs);
      }
    },
    isDisabled() {
      if (this.name === '' && this.genders.length === 0 && this.club === null) {
        return true;
      }
      return false;
    },
  },

  methods: {
    fetchClubs() {
      api().get('admin/clubs')
        .then((response) => {
          this.clubs = sortBy(response.data, 'name');
          this.loading = false;
        }).catch(() => {
          this.loading = false;
        });
    },

    selected(player) {
      this.$router.push(`/clubs/${player.club_id}/players/${player.id}`);
    },

    search() {
      api().post('/admin/players/search', { name: this.name, genders: this.genders, club_id: this.club })
        .then((response) => {
          this.players = response.data.results;
          this.searched = true;
        });
    },

    newSearch() {
      this.searched = false;
      this.players = [];
      this.name = '';
      this.genders = [];
      this.club = null;
    },
  },

  created() {
    this.fetchClubs();
  },
};
</script>
